import request from '@/utils/request'



export function loginReq(data){
    return request({
        'url': '/login',
        data,
        'method': 'post'
    })
}


export function getServerInfo(){
    return request({
        'url': '/admin/server',
        'method': 'get'
    })
}

export function getFishMonthNum(date){
    if(typeof date === "undefined")
    date = null

    return request({
        'url': '/admin/fishmonthnum',
        'data':{'date':date},
        'method': 'post'
    })
}