import request from '@/utils/request'
//人员列表
 export function getUserList(page) {
    return request({
        url: '/admin/user/list?page='+page,
        method: 'get'
    })
}



//新增渔民信息
export function AddFishUser(data) {
    return request({
        url: '/admin/user/addUser',
        method: 'post',
        data
    })
}


//删除渔民
export function DelUser(id) {
    return request({
        url: '/admin/user/del/'+id,
        method: 'delete',
        
    })
}


