export const title = '渔网有点大'
//export const baseurl = 'https://api.mgylba.cn'
//export const baseurl = 'http://127.0.0.1:88'
export const baseurl = 'https://qc.mgylba.cn:12345'


export default{
    baseurl,
    title
}
