import axios from "axios";
import { getToken ,setToken} from "./auth";
import store from '@/store'
import { baseurl} from "@/settings";
export default (config) => {
const instance = axios.create({
    baseURL: baseurl + '/api/',
    timeout:20000,
})
// 添加请求拦截器
instance.interceptors.request.use(config=>{
    //添加token头信息 验证身份
    if (getToken()){
        config.headers['Authorization'] = 'Bearer ' + getToken()
    }
   
    return config
},error=>{
    console.log(error)
    return Promise.reject(error)
})
// 添加响应拦截器
instance.interceptors.response.use(response=>{
    // 验证状态码 200 通过  401 跳转到登录页面
    const res = response.data
    const headers = response.headers
   
    if (res.code === 200){
        //response.headers.authorization
        //返回了刷新的token 就放到locaStore里面去
        if ('authorization' in headers){
            setToken(headers.authorization)
        }
    }
    if(res.code === 401){
        store.dispatch('user/resetToken').then(() => {
            location.reload()
        })
    }
    
    return res
},error=>{
    console.log('err' + error) // for debug
    return Promise.reject(error)
})

    return instance(config);
}