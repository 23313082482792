import request from '@/utils/request'

//获取鱼类每日信息
export function getFishInfoList(page,data) {
    return request({
        url: '/list?page=' + page,
        method: 'post',
        data
    })
}
//获取类型列表
export function getFishTypeList(page) {
    return request({
        url: '/typelist?page=' +page,
        method: 'get',
    })
}

//提交每日卖鱼数据
export function AddFishSellInfo(data) {
    return request({
        url: '/addinfo',
        method: 'post',
        data
    },)
}
//获取所有表格
export function GetExcel(data){
    return request({
        url:'/admin/export',
        method:'post',
        responseType: 'blob',
        data
    })
}
