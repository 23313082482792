import { setToken, removeToken} from '@/utils/auth'
import { loginReq} from '@/api/index.js'
import {getUserList as getUserListReq} from'@/api/user.js'
import {getFishTypeList as getFishTypeListReq} from'@/api/fishinfo.js'

const mutations = {

}

const state = {
    UserList:{},
    FishTypeList:{},
}

const actions = {
    //user login
    // eslint-disable-next-line no-unused-vars
    login({commit},data){
        return new Promise((resolve,reject)=>{
            loginReq(data).then((res)=>{
                if(res.code ===200){
                    setToken(res.token)
                    resolve()
                }else{
                    reject(res)
                }
            }).catch((err)=>{
                reject(err)
            })
        })

    },
    // reset or del token
    resetToken(){
        return new Promise((resolve)=>{
            removeToken()
            resolve()
        })
    },

    //UserInfo
      getUserList(){
        return new Promise((resolve,reject)=>{
         getUserListReq(0).then((res)=>{
                if(res.code ==200){
                   state.UserList = res.data
                   resolve()
                 }else{
                    reject(res)
                }
            }).catch((err)=>{
                reject(err)
            })
               
        })
      
    },

    //FishType
      getFishType(){
          getFishTypeListReq(0).then(res=>{
            if(res.code ==200){
               state.FishTypeList = res.data
            }
        })
    }
}

export default {
    namespaced: true,
    mutations,
    state,
    actions
}