/*
1 设置白名单
2 验证身份后直接过
3 动态挂载有权限的路由
*/

import router from '@/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, GetPageTitle} from '@/utils/auth'

const WhiteList =['/login'];
//路由加载前
// eslint-disable-next-line no-unused-vars
router.beforeEach((to,from)=>{
    //修改页面标题信息
    document.title = GetPageTitle(to.meta.title)
    NProgress.start()  //后台进度条
    //判断有没有token 没有跳转login 有就下一步
    if (getToken()){
        if(to.path === '/login'){
            router.push('/');
        }
        return true
    }else{ //没有token
        if (WhiteList.indexOf(to.path) !== -1){
            NProgress.done()
            return true  //白名单 直接过
        }else{
            if (to.path == '/dashboard'){
                router.push(`/login`)
            }else{
                router.push(`/login?redirect=${to.path}`)
            }
            
            NProgress.done()
            console.log(to.path)
            return true
        }
    }
    
    //获取登录信息路由
    //挂载路由 如果有token访问login 跳转到主页
})
//路由加载后
router.afterEach(() => {
    NProgress.done()
})
