import { createRouter, createWebHashHistory } from 'vue-router'
const Layout = () => import('@/layout')

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/Login.vue'),
    hidden: true,
    meta: { title: '登录页面' }
  },
  {
    
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/index.vue'),
      meta: { title: '首页信息', ico: 'House' }
    }]
  },
  {
    path: '/Fish',
    component: Layout,
    redirect: '/Fish/list',
    meta: { title: '渔业信息管理', ico: 'User' },
    children: [
       {
        path: 'list',
        name: 'list',
        component: () => import('@/views/Fish/List.vue'),
        meta: { title: '登记列表', ico: 'Histogram' }
      },  
      {
        path: 'userlist',
        name: 'userlist',
        component: () => import('@/views/User/List.vue'),
        meta: { title: '人员管理', ico: 'User' }
      },
      {
        path: 'fishtype',
        name: 'fishtype',
        component: () => import('@/views/Fish/Type/TypeList.vue'),
        meta: { title: '水产品类别', ico: 'Filter' }
      },
   ]
  },


]

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
})

export default router
